import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import AppContext from "../../Context/AppContext";
import * as API from "../../Services/APIService";
import { encodeStr } from "../../Utils/Encypt";
import formBg from "../../assets/images/form_bg.jpg";
import liffHelper from "../../Utils/liffHelper";
import { isAdminPermission } from "../../Services/PermissionService";
import mime from "mime-types";

import {
  Button,
  Radio,
  Image,
  Space,
  Popconfirm,
  message,
  Input,
  Select,
  Avatar,
  Spin,
  Timeline,
  Divider,
  Table,
  Result,
  Progress,
  Tag,
  Modal,
  Collapse,
  Drawer
} from "antd";
import {
  CameraOutlined,
  CloudUploadOutlined,
  UserOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";

import * as _ from "lodash";
import * as moment from "moment";
import "./SiteStatus.scss";

export default function SiteStatus(props) {
  const selectRef = useRef(null);

  const { updateTime, setUpdateTime, empProfile, setEmpProfile } =
    useContext(AppContext);

  const [salesList, setSalesList] = useState([]); //  業務人員清單
  const [siteListForSale, setSiteListForSale] = useState([]); // 案場清單 for 業務
  const [pickedEmpId, setPickedEmpId] = useState(undefined); // 所選到的業務  empId
  const [pickedSiteId, setPickedSiteId] = useState(undefined); // 所選到的案場 id
  const [isLoading, setIsLoading] = useState(false); // loading 指示
  const [isUploading, setIsUploading] = useState(false); // 是否正在上傳
  const [docsSubTaskList, setDocsSubTaskList] = useState([]); // 送審 - 子任務清單
  const [engiSubTaskList, setEngiSubTaskList] = useState([]); // 工程 - 子任務清單
  const [docsProgress, setDocsProgress] = useState(0); // 送審 - 排程進度
  const [engiProgress, setEngiProgress] = useState(0); // 工程 - 排程進度
  const [capacityHistoryList, setCapacityHistoryList] = useState([]); // 容量修改歷程
  const [capacity, setCapacity] = useState(0); // 容量

  const [isShowUploadDrawer, setIsShowUploadDrawer] = useState(false); // 檔案上傳/列表 Drawer
  const [designEditingRecord, setDesignEditingRecord] = useState({}); // 設計 正在編輯的資料 record/row
  const [isShowFileListModal, setIsShowFileListModal] = useState(false); // 檔案清單 Modal 顯示
  const [fileList, setFileList] = useState([]); // 檔案清單

  const { Option, OptGroup } = Select;

  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ componentDidMount ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */
  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // 更新資料
    setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    //emp profile為空,導向login

    if (_.size(empProfile) === 0) {
      // 轉跳  page
      props.history.push("/login");
      return;
    }

    async function fetchData() {
      // 取得業務部人員清單
      const salesDeptObj = {
        qDeptName: "業務部",
        ...empProfile,
      };
      //取得人員清單 by 部門
      const salesEmpListRes = await API.getSalesEmpList(salesDeptObj);

      if (_.get(salesEmpListRes, "RESULT") === "OK") {
        const salesEmpList = JSON.parse(_.get(salesEmpListRes, "DATA"));
        const placeHoldeOption = [
          {
            key: "78335432-5821-4a97-ba54-8d0a2ff6b85e",
            empId: "請選擇",
            empName: "請選擇",
          },
          {
            key: "4a86f405-9647-4d0b-880e-93f1ef043049",
            empId: "ALL",
            empName: "全部業務",
            linePictureUrl: "https://joeschmoe.io/api/v1/random",
          },
        ];
        setSalesList([...placeHoldeOption, ...salesEmpList]);
      }

      // 設定業務
      const { empId } = empProfile;
      setPickedEmpId(empId);
      // setPickedEmpId("C190604");

      // 取得案場清單  by 業務 line id
      /*

      const 
      const getSiteListBySiteMgrLineIdRes = await getSiteListBySiteMgrLineId(
        empProfile
      );
      if (_.get(getSiteListBySiteMgrLineIdRes, "RESULT") === "OK") {
        setSiteList(JSON.parse(_.get(getSiteListBySiteMgrLineIdRes, "DATA")));
      }*/
    }

    // 抓資料
    fetchData();

    return () => {
      // componentWillUnmount is here!
    };
  }, [updateTime, empProfile]);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    async function fetchData() {
      // 取得案場清單  by 業務 emp id
      // empId 加密

      const encodeEmpId = encodeStr(pickedEmpId);
      const obj = {
        ...empProfile,
        qSaleEmpId: encodeEmpId,
      };

      const selectSiteListBySalesRes = await API.selectSiteListBySales(obj);

      if (_.get(selectSiteListBySalesRes, "RESULT") === "OK") {
        const ssss = JSON.parse(_.get(selectSiteListBySalesRes, "DATA"));

        const uniCityList = _.uniqBy(ssss, "city");

        const data = JSON.parse(_.get(selectSiteListBySalesRes, "DATA"));

        if (_.size(data) > 0) {
          const { siteId } = data[0];
          setPickedSiteId(siteId);
        }

        setSiteListForSale(data);
      }
    }

    // loading 開始
    setIsLoading(true);

    // 抓資料
    if (pickedEmpId !== undefined) {
      fetchData();
    }

    // loading 結束
    setIsLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, [pickedEmpId]);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    async function fetchData() {
      // 取得子任務清單 by site id
      // siteId 加密

      const encodeSiteId = encodeStr(pickedSiteId);
      const obj = {
        ...empProfile,
        siteId: encodeSiteId,
      };

      // 送審 - 取得子任務清單 by site id
      const getDocsSubTaskListBySiteIdRes =
        await API.getDocsSubTaskListBySiteId(obj);

      if (_.get(getDocsSubTaskListBySiteIdRes, "RESULT") === "OK") {
        setDocsSubTaskList(
          JSON.parse(_.get(getDocsSubTaskListBySiteIdRes, "DATA"))
        );
      }

      // 工程 - 取得子任務清單 by site id
      const getEngiSubTaskListBySiteIdRes =
        await API.getEngiSubTaskListBySiteId(obj);

      if (_.get(getEngiSubTaskListBySiteIdRes, "RESULT") === "OK") {
        setEngiSubTaskList(
          JSON.parse(_.get(getEngiSubTaskListBySiteIdRes, "DATA"))
        );
      }

      //送審 - 取得送審進度
      const getDocsProgressRes = await API.getDocsProgress(obj);

      if (_.get(getDocsProgressRes, "RESULT") === "OK") {
        const data = JSON.parse(_.get(getDocsProgressRes, "DATA"));

        //progress
        if (_.get(data, "progress") === undefined) {
          setDocsProgress(0);
        } else {
          setDocsProgress(_.get(data, "progress"));
        }
      }

      //取得工程進度
      const getEngiProgressRes = await API.getEngiProgress(obj);

      if (_.get(getEngiProgressRes, "RESULT") === "OK") {
        const data = JSON.parse(_.get(getEngiProgressRes, "DATA"));

        //progress
        if (_.get(data, "progress") === undefined) {
          setEngiProgress(0);
        } else {
          setEngiProgress(_.get(data, "progress"));
        }
      }

      // 取得業務案場
      const getSaleSiteInfoRes = await API.getSaleSiteInfo(obj);
      if (_.get(getSaleSiteInfoRes, "RESULT") === "OK") {
        const saleSiteInfo = JSON.parse(_.get(getSaleSiteInfoRes, "DATA"));
        if (_.get(saleSiteInfo, "capacity") !== undefined) {
          setCapacity(_.get(saleSiteInfo, "capacity"));
        } else {
          setCapacity(0);
        }
      }

      // 取得 容量修改歷程
      const getCapacityHistoryListRes = await API.getCapacityHistoryList({
        ...obj,
        siteId: pickedSiteId,
      });

      if (_.get(getCapacityHistoryListRes, "RESULT") === "OK") {
        const myCapacityHistoryListRes = JSON.parse(
          _.get(getCapacityHistoryListRes, "DATA")
        );

        //保存容量修改歷程
        setCapacityHistoryList(myCapacityHistoryListRes);
      }
    }

    // loading 開始
    setIsLoading(true);

    // 抓資料
    if (pickedSiteId !== undefined) {
      fetchData();
    }

    // loading 結束
    setIsLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, [pickedSiteId]);

    /**
   *
   * for 設計
   * 打開檔案上傳區
   *
   *
   */
    const openUploadFileDrawer = async (_code, _desc, _siteId) => {
      console.log(_siteId);
      // 檢查主檔是否已存在 uuid
      const checkDesingTaskUuidRes = await API.checkDesingTaskUuid({
        siteId: _siteId,
        type: _desc,
        designTaskDesc: _desc,
      });
  
      if (_.get(checkDesingTaskUuidRes, "RESULT") === "OK") {
        const mstModel = JSON.parse(_.get(checkDesingTaskUuidRes, "DATA"));
  
        const { uuid } = mstModel;
  
        let fileList = [];
  
        // 抓之前上傳清單
        const getDesingFileListRes = await API.getDesingFileList({
          uuid,
        });
        const desingFileListAll = JSON.parse(_.get(getDesingFileListRes, "DATA"));
  
        fileList = _.filter(
          desingFileListAll,
          (item) => item.designTaskVersion === undefined
        );
  
        const desingFileList = _.filter(
          desingFileListAll,
          (item) => item.designTaskVersion !== undefined
        );

        // 保存正在編輯資料
        setDesignEditingRecord({
          _siteId,
          uuid,
          fileList,
          desingFileList,
          designTaskDesc: _desc,
          ts: moment().format("YYYY-MM-DD HH:mm:ss.sss"),
        });
  
        // 打開drawer
        setIsShowUploadDrawer(true);
      } else {
        message.error("上傳系統異常 !");
      }
    };

      /**
   *
   * 下載全部檔案
   *
   *
   */
  const onDownloadAllFile = (_ver) => {
    const { desingFileList } = designEditingRecord;

    const fileList = _.filter(
      desingFileList,
      (item) => item.designTaskVersion === _ver
    );

    if (_.size(fileList)) {
      _.forEach(fileList, (fileItem) => {
        const { fileLink, fileName } = fileItem;
        downloadFile(fileLink, fileName);
      });

      //fileLink
      //fileName
    }
  };

    /**
   *
   *
   * 下載檔案
   *
   */
    const downloadFile = (_fileLink, _fileName) => {
      // 取得 mime type
      const mimeType = mime.lookup(_fileName);
  
      fetch(_fileLink, {
        method: "GET",
        headers: {
          "Content-Type": mimeType,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", _fileName);
  
          // Append to html link element page
          document.body.appendChild(link);
  
          // Start download
          link.click();
  
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    };

      /**
   *
   *
   * 下載檔案
   *
   */
  const onDownloadFile = async (_code, _siteId) => {
    const getFileListBySiteIdCodeRes = await API.getFileListBySiteIdCode({
      siteId:_siteId,
      code: _code,
    });

    if (_.get(getFileListBySiteIdCodeRes, "RESULT") === "OK") {
      const getFileList = JSON.parse(_.get(getFileListBySiteIdCodeRes, "DATA"));

      //資料集

      setFileList(getFileList);

      // 資料清單 Model
      setIsShowFileListModal(true);
    }
  };
  
    const { Panel } = Collapse;
  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ JSX ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */

  return (
    <div className="site-status-comp">
      {/* 背景圖檔 */}
      {/* <div className="bg-image">
        {" "}
        <img src={formBg} alt="" />
      </div> */}
      {/* header */}
      <div className="header">
        <div className="title">CIRSOLAR</div>
        <div className="sub-title">案場進度</div>
      </div>
      <Spin tip="Loading..." spinning={isLoading}>
        {/* form area */}
        <div className="form-area">
          <div className="input-area">
            {/* 業務人員 */}

            <div className="input-item">
              <div className="label">業務人員 </div>
              <div className="input-field">
                <Select
                  style={{ width: "100%" }}
                  value={pickedEmpId}
                  defaultValue="請選擇"
                  onChange={(val) => setPickedEmpId(val)}
                  disabled={!isAdminPermission(empProfile, "SALE")}
                >
                  {salesList.map((item, index) => {
                    return (
                      <Option
                        value={item.empId}
                        key={`${item.key}-${index}`}
                        className="emp-select-option"
                        title={item.empId}
                      >
                        {/* 有 line 頭像 */}
                        {_.size(item.linePictureUrl) > 0 && (
                          <div>
                            <Avatar size="small" src={item.linePictureUrl} />
                            <span className="emp-select-option-name">
                              {item.empId} - {item.empName}
                            </span>
                          </div>
                        )}

                        {/* 沒有 line 頭像 */}
                        {_.size(item.linePictureUrl) === 0 && (
                          <div>
                            <Avatar
                              style={{ backgroundColor: "#87d068" }}
                              icon={<UserOutlined />}
                              size="small"
                            />
                            <span className="emp-select-option-name">
                              {item.empId} - {item.empName}
                            </span>
                          </div>
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            {/* 案場名稱 */}
            <div className="input-item">
              <div className="label" id="site-search-title">
                案場名稱 {_.size(siteListForSale)}{" "}
              </div>
              <div className="input-field">
                <Select
                  showSearch
                  value={pickedSiteId}
                  style={{ width: "100%" }}
                  placeholder="案場名稱"
                  optionFilterProp="children"
                  filterOption={(input, optionOrGroup) => {
                    const isGroup = Array.isArray(optionOrGroup.options);
                    if (isGroup) {
                      return false;
                    }
                    const { children } = optionOrGroup;

                    return _.includes(children, input);
                  }}
                  filterSort={(optionOrGroupA, optionOrGroupB) => {
                    return 1;
                  }}
                  onChange={(val) => {
                    setPickedSiteId(val);
                    selectRef.current.blur();
                  }}
                  onFocus={() =>
                    document
                      .getElementById("site-search-title")
                      .scrollIntoView(true)
                  }
                  onBlur={() => selectRef.current.blur()}
                  ref={selectRef}
                >
                  {_.uniqBy(siteListForSale, "city").map(
                    (cityItem, cityIndex) => (
                      <OptGroup
                        label={cityItem.city}
                        key={`dkckosk-${cityIndex}`}
                      >
                        {_.filter(
                          siteListForSale,
                          (o) => o.city === cityItem.city
                        ).map((item, index) => (
                          <Option
                            value={item.siteId}
                            key={`SI-${item.siteId}-${_.padStart(
                              index,
                              5,
                              "0"
                            )}`}
                          >
                            {item.siteName}
                          </Option>
                        ))}
                      </OptGroup>
                    )
                  )}

                  {/* {siteListForSale.map((item, index) => (
                  <Option value={item.siteId} key={`${item.siteId}-${index}`}>
                    {item.siteName}
                  </Option>
                ))} */}
                </Select>
              </div>
            </div>
          </div>
        </div>

        {/*  時間軸區 */}
        <div className="timeline-container">
          {/* 容量修改歷程  - 時間軸區 */}
          <div className="timeline-area">
            <div className="title capacity-title">
              容量修改歷程 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
            </div>
            <Divider />

            <div className="last-capacity">
              <h4>最新容量: {capacity.toLocaleString()} kWp</h4>
            </div>
            {/* <Divider /> */}
            {_.size(capacityHistoryList) > 0 && <Divider />}

            {_.size(capacityHistoryList) === 0 && (
              <div className="hide-in-mobile">
                <Divider />
              </div>
            )}

            <Timeline>
              {_.size(capacityHistoryList) > 0 &&
                capacityHistoryList.map((item, index) => {
                  return (
                    <Timeline.Item key={`caphis-${item.capacity}-${index}`}>
                      <p>
                        {moment(item.dateUpdate).format("YYYY/MM/DD HH:mm:ss")}
                      </p>
                      <p>
                        容量: <Tag color="green">{item.capacity}</Tag>
                      </p>
                      <p>
                        備註:{" "}
                        <Tag color="blue">
                          {_.size(item.fixRemark) ? item.fixRemark : "---"}
                        </Tag>{" "}
                      </p>
                      <p>修改人: {item.userUpdateName}</p>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(capacityHistoryList) === 0 && (
              <div className="no-data-area hide-in-mobile">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>

          {/* 送審進度- 時間軸區 */}
          <div className="timeline-area">
            <div className="title docs-title">
              送審進度 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
              <Progress percent={docsProgress} status="active" />
            </div>
            <Divider />

            <Timeline>
              {_.size(docsSubTaskList) > 0 &&
                docsSubTaskList.map((item, index) => {
                  return (
                    <Timeline.Item
                      key={`${item.siteId}-${index}`}
                      color={item.progress === 100 ? "green" : "gray"}
                      dot={<CheckCircleFilled />}
                    >
                      {item.progress > 99 ? (
                        <p>
                          {moment(item.dateStart).format("YYYY/MM/DD")} -{" "}
                          {moment(item.dateEnd).format("YYYY/MM/DD")}(
                          {moment(item.dateEnd).diff(
                            moment(item.dateStart),
                            "day"
                          )}{" "}
                          天 )
                        </p>
                      ) : (
                        <p>{moment(item.dateStart).format("YYYY/MM/DD")}</p>
                      )}
                    <p>
                      {item.taskName.includes("送審圖(先不開)") ? (
                        <div
                          onClick={() => openUploadFileDrawer("p10", "送審圖", item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.taskName}  <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ) : item.taskName.includes("違建圖(先不開)") ? (
                        <div
                          onClick={() => openUploadFileDrawer("p20", "違建圖", item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      )  : item.taskName.includes("綠能圖(先不開)") ? (
                        <div
                          onClick={() => openUploadFileDrawer("p30", "綠能圖", item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      )  : item.taskName.includes("細協圖(先不開)") ? (
                        <div
                          onClick={() => openUploadFileDrawer("p70", "細協圖", item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ) : item.taskName.includes("結構圖(先不開)") ? (
                        <div
                          onClick={() => openUploadFileDrawer("p60", "結構圖", item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ) : item.taskName.includes("併聯審查") ? (
                        <div
                          onClick={() =>onDownloadFile("P01",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ) : item.taskName.includes("同意備案") ? (
                        <div
                          onClick={() =>onDownloadFile("V01",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("細部協商") ? (
                        <div
                          onClick={() =>onDownloadFile("P05",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("審訖圖") ? (
                        <div
                          onClick={() =>onDownloadFile("C303",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("結構技師") ? (
                        <div
                          onClick={() =>onDownloadFile("B02",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("免雜") ? (
                        <div
                          onClick={() =>onDownloadFile("W04",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("併聯試運轉") ? (
                        <div
                          onClick={() =>onDownloadFile("P08",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("竣工備查") ? (
                        <div
                          onClick={() =>onDownloadFile("A01",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("設備登記") ? (
                        <div
                          onClick={() =>onDownloadFile("V02",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): item.taskName.includes("正式躉售") ? (
                        <div
                          onClick={() =>onDownloadFile("P99",  item.siteId)}
                          style={{ cursor: "pointer" }}
                        >
                         {item.taskName} <Tag color="blue"><EyeOutlined/></Tag>
                        </div>
                      ): (
                        item.taskName
                      )}
                    </p>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(docsSubTaskList) === 0 && (
              <div className="no-data-area">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>

          {/* 工程進度 - 時間軸區 */}
          <div className="timeline-area">
            <div className="title engi-title">
              工程進度 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
              <Progress
                percent={engiProgress}
                status="active"
                strokeColor="#87d068"
              />
            </div>
            <Divider />
            <Timeline>
              {_.size(engiSubTaskList) > 0 &&
                engiSubTaskList.map((item, index) => {
                  return (
                    <Timeline.Item
                      key={`${item.siteId}-${index}`}
                      color={item.progress > 99 ? "green" : "gray"}
                      dot={<CheckCircleFilled />}
                    >
                      {item.progress > 99 ? (
                        <Fragment>
                          <p>
                            {moment(item.dateStart).format("YYYY/MM/DD")} -{" "}
                            {moment(item.dateEnd).format("YYYY/MM/DD")} (
                            {moment(item.dateEnd).diff(
                              moment(item.dateStart),
                              "day"
                            )}{" "}
                            天 )
                          </p>
                          <p> {item.engiName}</p>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <p>{moment(item.dateStart).format("YYYY/MM/DD")}</p>
                          <p>
                            {item.engiName}
                            <Progress
                              percent={item.progress}
                              steps={4}
                              className="item-progress"
                            />
                          </p>
                        </Fragment>
                      )}
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(engiSubTaskList) === 0 && (
              <div className="no-data-area">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>
        </div>
      </Spin>

      {/* 檔案清單 Modal  */}
      <Modal
        title="檔案清單"
        visible={isShowFileListModal}
        onOk={() => setIsShowFileListModal(false)}
        onCancel={() => setIsShowFileListModal(false)}
      >
        {_.map(fileList, (item) => {
          return (
            <p>
              <a href={item.fileLink} target="_blank">
                {item.fileName}
              </a>
            </p>
          );
        })}
      </Modal>
      {/* 上傳檔案 Drawer */}
      <Drawer
        title={`${_.get(
          designEditingRecord,
          "designTaskDesc"
        )}   `}
        placement="right"
        onClose={() => {
          setDesignEditingRecord({
            ts: moment().format("YYYY-MM-DD HH:mm:ss.sss"),
          });
          setIsShowUploadDrawer(false);
        }}
        visible={isShowUploadDrawer}
        width="500"
        className="design-upload-file-area"
      >
        {/* 檔案版本列表 */}
        <h2 className="version-list-title">
          檔案版本列表(設計:
          {_.get(designEditingRecord, "desingFileList") !== undefined && (
            <span>
              {
                _.get(designEditingRecord, "desingFileList")[0][
                "designerEmpName"
                ]
              }
            </span>
          )}
          )
        </h2>

        <Collapse
          defaultActiveKey={["designTaskVersion-1"]}
          ghost
          accordion
          key={`desing-Collapse-${updateTime}`}
        >
          {
            // 各版本 for each
            _.map(
              _.unionBy(
                _.get(designEditingRecord, "desingFileList"),
                "designTaskVersion"
              ),
              (verItem, verIndex) => {
                return (
                  <Panel
                    header={verItem.designTaskVersion}
                    key={`designTaskVersion-${verIndex + 1}`}
                  >
                    {/* 版本下的註解 */}
                    <div className="remark">
                      版本備註:
                      <span>
                        {
                          _.filter(
                            _.get(designEditingRecord, "desingFileList"),
                            (item) =>
                              item.designTaskVersion ===
                              verItem.designTaskVersion
                          )[0].remark
                        }
                      </span>
                    </div>

                    {/* 版本下的風速 */}
                    {_.get(designEditingRecord, "designTaskDesc") ===
                      "結構圖" && (
                        <div className="remark">
                          風速:
                          <span>
                            {
                              _.filter(
                                _.get(designEditingRecord, "desingFileList"),
                                (item) =>
                                  item.designTaskVersion ===
                                  verItem.designTaskVersion
                              )[0].windSpeed
                            }
                          </span>
                          <span> / I 值:</span>
                          <span>
                            {
                              _.filter(
                                _.get(designEditingRecord, "desingFileList"),
                                (item) =>
                                  item.designTaskVersion ===
                                  verItem.designTaskVersion
                              )[0].iValue
                            }
                          </span>
                        </div>
                      )}

                    {/* 版本下的檔案清單 */}
                    {_.map(
                      _.filter(
                        _.get(designEditingRecord, "desingFileList"),
                        (item) =>
                          item.designTaskVersion === verItem.designTaskVersion
                      ),
                      (fileItem, fileIndex) => {
                        return (
                          <div className="design-file-item">
                            <a
                              key={`${fileItem.designTaskVersion}-${fileItem.fileName}`}
                              href={fileItem.fileLink}
                              target="_blank"
                            >
                              {fileIndex + 1}. {fileItem.fileName}
                            </a>
                          </div>
                        );
                      }
                    )}

                    {/* 下載全部檔案 */}
                    <Button
                      type="primary"
                      shape="round"
                      icon={<DownloadOutlined />}
                      key={`${verItem.designTaskVersion}-dl-all-btn`}
                      onClick={() =>
                        onDownloadAllFile(verItem.designTaskVersion)
                      }
                    >
                      Download All
                    </Button>
                  </Panel>
                );
              }
            )
          }

          {/* <Panel header="This is panel header 1" key="123132132">
            <p>123132132</p>
          </Panel> */}
        </Collapse>
      </Drawer>
    </div>
  );
}

